<template>
    <v-main>
        <div class="main-content">
            <Card class="sticky-filters mb-15 card-custom">
                <template #title>
                  <Toolbar>
                    <template #start>
                    </template>
                    <template #end>
                      <Button :label="$t('create_new_notification')" 
                      icon="pi pi-plus" 
                      class="p-button-sm p-button-success" 
                      @click="notificationModal = true" />
                      </template>
                </Toolbar>
                </template>
                <template #content>
                  <v-chip-group>
                  <v-chip class="active-filter-chip mb-1" v-for="(value, key) in filteredActiveFilters" :key="'filter' + key"
                      >
                      <template>
                        {{ $t(key) }}: {{ getFilterValueText(key, value) }}
                      </template>
                      <v-icon @click.stop="removeFilter(key)">mdi-close</v-icon>
                    </v-chip>
                  </v-chip-group>
                  <DataTable 
                  :value="notifications.items" 
                  :rows="notifications.size"
                  :paginator="true"
                   :loading="loadingFlag" 
                   filterDisplay="row"
                  responsiveLayout="responsive"
                  :scrollable="true" 
                    stripedRows
                    removableSort
                    class="p-datatable-sm">
                    <template #empty>
                      {{ $t('no_data') }}
                      </template>
                    <Column sortable field="id" header="ID" :showFilterMenu="false" :styles="{'width': '5rem'}">
                      <template #body="{data}">
                        <div>
                          {{ data.id }}
                        </div>
                      </template>
                      <template #filter>
                      </template>
                    </Column>
                    <Column sortable field="user_id" :header="$t('user_id')" :showFilterMenu="false" :styles="{'width': '5rem'}">
                      <template #body="{data}">
                        <router-link :to="`/player/${data.user_id}`">
                          {{ data.user_id }}
                        </router-link>
                      </template>
                      <template #filter>
                        <InputText class="p-inputtext-sm" placeholder="ID"
                        @input="debounceFilters"
                          v-model="filters.user_id">
                        </InputText>
                      </template>
                    </Column>
                    <Column field="type" :header="$t('type')" :showFilterMenu="false" :styles="{'min-width':'5rem'}">
                        <template #body="{data}">
                          <span class="custom-badge"
                          :class="getTypeClass(data.type)"
                          >
                            <span>{{ getType(data.type) }} </span>
                          </span>
                      </template>
                      <template #filter>
                        <v-menu v-model="typeMenu" 
                  offset-y
                  :close-on-content-click="false" transition="scale-transition"
                      nudge-left="0" nudge-right="0">
                      <template v-slot:activator="{ on }">
                          <span class="p-input-icon-right" v-on="on" >
                            <i class="pi pi-angle-down" />
                            <InputText 
                            :value="getType(filters.type)"
                            type="text" readonly class="p-inputtext-sm" :placeholder="$t('type')">
                            </InputText>
                          </span>
                      </template>
                        <Listbox v-model="filters.type" :options="typeMessageOptions"
                        @change="applyFilters"
                        :emptyFilterMessage="$t('no_data')"
                        optionLabel="name"
                        optionValue="value"
                        style="width:15rem" />
          
                    </v-menu>
                      </template>
                      </Column>
                      <Column field="message" :header="$t('message')" :showFilterMenu="false" :styles="{'min-width':'10rem'}">
                        <template #body="{data}">
                            <span>{{ data.message }} </span>
                      </template>
                      <template #filter>
                      </template>
                      </Column>
                      <Column field="is_viewed" :header="$t('is_viewed')" :showFilterMenu="false" :styles="{'min-width':'10rem'}">
                        <template #body="{data}">
                            <span :class="getIsViewedClass(data.is_viewed)">{{ getIsViewed(data.is_viewed) }} </span>
                      </template>
                      <template #filter>
                        <v-menu v-model="isViewedMenu" 
                  offset-y
                  :close-on-content-click="false" transition="scale-transition"
                      nudge-left="0" nudge-right="0">
                      <template v-slot:activator="{ on }">
                          <span class="p-input-icon-right" v-on="on" >
                            <i class="pi pi-angle-down" />
                            <InputText 
                            :value="getIsViewed(filters.is_viewed)"
                            type="text" readonly class="p-inputtext-sm" :placeholder="$t('is_viewed')">
                            </InputText>
                          </span>
                      </template>
                        <Listbox v-model="filters.is_viewed" :options="isViewedOptions"
                        @change="applyFilters"
                        :emptyFilterMessage="$t('no_data')"
                        optionLabel="name" 
                        optionValue="value"
                        style="width:15rem" />
          
                    </v-menu>
                      </template>
                      </Column>
                      <Column field="created_at" :header="$t('date')" :showFilterMenu="false" :styles="{'max-width':'25rem'}">
                        <template #body="{data}">
                          <div style="white-space: nowrap">
                            {{ new Date(data.created_at).toLocaleString() }}
                          </div>
                      </template>
                      <template #filter>
                      </template>
                      </Column>
                    <!-- <Column :exportable="false" 
                    :header="$t('actions')"
                    
                    >
                      <template #body="{data}">
                          <div class="d-flex align-center">
                            <Button icon="pi pi-pencil" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditBonus(data)" />
                            <Button icon="pi pi-trash" :disabled="data.loadingItem" :loading="data.loadingItem" class="p-button-sm p-button-rounded p-button-danger" @click="toggleConfirmDeleteDialog(data)" />
                          </div>
                        </template>
                  </Column> -->
                  </DataTable>
                  <games-paggination 
                  :pagginationObject="filters" 
                  :requestData="notifications" 
                  @apply-filters="toGetNotifications" 
                  :isStatic="false"
                  />
                  
                  <v-dialog v-model="notificationModal" content-class="br-16" max-width="500">
                      <v-card>
                        <v-card-title class="pb-0 justify-center"> 
                          <h3>{{ $i18n.t('new_notification') }}</h3>
                          <close-modal-button 
                          @close-modal="notificationModal = false"
                          />
                  </v-card-title>
                        <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
                          <v-card-text class="pb-0 pt-1 text-left space-y-2 form-block">
                                          <ValidationProvider
                                  :name="$t('type')"
                                  rules="required"
                                  v-slot="{ errors, validate, validated }"
                                > 
                                  <h4 class="c-text white-space title-input">{{ $t('type') }}:</h4>
                                  <div class="wrapper-input">
                                    <!-- :minFractionDigits="2" -->
                                    <InputText 
                                    disabled
                                    v-model="notification.type"
                                     class="p-inputtext-sm fw" :placeholder="$t('type')">
                                    </InputText>
                                  </div>
                              </ValidationProvider>
                              <span> 
                                <h4 class="c-text white-space title-input align-center">
                                {{ $t('type_players') }} 
                                <span class="c-red">*</span>:
                                </h4>
                                <div class="wrapper-input">
                                  <SelectButton v-model="isType" 
                                  :options="typeOptions" optionLabel="name" optionValue="value"
                                  />
                                </div>
                                </span>
                                  <template v-if="isType == 'segment'">
                                    <div class="d-flex align-center">
                                      <h4 class="c-text white-space title-input">{{ $t('segment') }}:</h4>
                                        <!-- :minFractionDigits="2" -->
                                        <v-menu v-model="notificationMenu" 
                                        offset-y
                                        :close-on-content-click="false" 
                                            nudge-left="0" 
                                            nudge-right="0"
                                            
                                            >
                                            <template v-slot:activator="{ on }">
                                              <div class="fw" v-on="on">
                                                <ValidationProvider
                                      :name="$t('segment')"
                                      rules="required"
                                      v-slot="{ errors, validate, validated }"
                                      class="d-flex flex-column fw align-start"
                                    > 
                                              <span class="p-input-icon-right fw"  >
                                                <i class="pi pi-angle-down" />
                                                  <InputText 
                                                  readonly
                                                  v-model="notification.segment_id"
                                                   class="p-inputtext-sm fw" :placeholder="$t('segment')">
                                                  </InputText>
                                                  </span>
                                                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                                  </div>
                                            </template>
                                              <Listbox v-model="notification.segment_id" :options="segments.items"
                                              @change="notificationMenu = false"
                                              :filter="true"
                                              class="custom-dropdown"
                                              :emptyFilterMessage="$t('no_data')"
                                              optionLabel="name" 
                                              optionValue="id"
                                              
                                              style="width:auto">
                                              </Listbox>
                                
                                          </v-menu>
                                    </div>
                                      <!-- <span v-if="segmentError" class="p-error mt-1 font-weight-bold">{{ $t('required_field') }}</span> -->
                                  </template>
                                          <ValidationProvider
                                  :name="$t('message')"
                                  rules="required"
                                  v-slot="{ errors, validate, validated }"
                                > 
                                  <h4 class="c-text white-space title-input">{{ $t('message') }}<span class="c-red">*</span>:</h4>
                                  <div class="wrapper-input">
                                    <!-- :minFractionDigits="2" -->
                                    <Textarea v-model="notification.message" 
                                  :autoResize="true" rows="5" class="fw" />
                                    <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                                  </div>
                              </ValidationProvider>
                              <!-- <span> 
                                  <h4 class="c-text white-space title-input align-center">
                                  {{ $t('for_all_users') }}: 
                                  <span class="c-red">*</span>
                                  <Badge value="?" severity="info" class="info-badge ml-1" v-tooltip.top="$t('all_users_notif_hint')"></Badge>
                                  </h4>
                                  <div class="wrapper-input">
                                  <InputSwitch class="p-inputswitch-success" 
                                  disabled
                                  v-model="notification.all_users" />
                                  </div>
                                  </span> -->
                          </v-card-text>
                          <v-card-actions>
                            <Button @click="handleSubmit(validate)" :loading="formLoading" 
                            :disabled="formLoading" 
                              :label="$t('create')" icon="pi pi-save"
                              class="p-button-sm fw p-button-success"
                              
                              />
                          </v-card-actions>
                      </ValidationObserver>
                      </v-card>
                    </v-dialog> 
                </template>
       
      </Card>
    </div>
    </v-main>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
    export default {
        name: 'Notifications',
        data() {
            return {
                formLoading: false,
                loadingFlag: false,
                loadingPage: false,
                notificationModal: false,
            notificationMenu: false,
            isType: 'all',
            notification: {
              type: 'MESSAGE',
              message: '',
              segment_id: '',
              all_users: true,
            },
            isViewedMenu: false,
            isViewedOptions: [
              {
                name: this.$t('yes'),
                value: true
              },
              {
                name: this.$t('no'),
                value: false
              },
            ],
            typeMenu: false,
            typeMessageOptions: [
              { name: this.$t('level'), value: 'LEVEL' },
              { name: this.$t('message'), value: 'MESSAGE' },
              { name: this.$t('bonus'), value: 'BONUS' },
              { name: this.$t('ban'), value: 'BAN' },
              { name: this.$t('compensation'), value: 'COMPENSATION' },
            ],
            filters: {
              user_id: '',
              type: '',
              is_viewed: '',
              page: 1,
              size: 25
            },
            startPage: 1,
            startSize: 25,
            typeOptions: [
              {
                name: this.$t('all_players'),
                value: 'all'
              },
              {
                name: this.$t('segment'),
                value: 'segment'
              }
            ]
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.$store.commit('setPageName', 'notifications')
            })
        },
        computed:{
            ...mapGetters({
                notifications: 'notifications/getAllNotifications',
                segments: 'segments/getSegments',
            }),
            filteredActiveFilters() {
          return Object.fromEntries(
            Object.entries(this.filters).filter(
              ([key, value]) => key !== 'size' && key !== 'page' && value !== ''
            )
          );
        },
        },
        async mounted() {
            this.loadingPage = true
            if (this.notifications.items.length === 0) {
                await this.toGetNotifications()
            }
            if (this.segments.items.length === 0) {
                await this.toGetSegments()
            }
            this.loadingPage = false
        },
        methods: {
          getFilterValueTitle(key) {
      // chip keys
      switch (key) {
        case 'country_name':
          return this.$t('country')
        case 'blocked':
          return this.$t('blocked')
      }

      return key;
    },
    getFilterValueText(key, value) {
      // chip values
      switch (value) {
        case true:
          return this.$t('yes')
        case false:
          return this.$t('no')
      }
        return value;
    },
    async removeFilter(key) {
      this.loadingFlag = true;
      // Delete chip
      this.$set(this.filters, key, '');
      // empty string
      if (typeof this.filters[key] === 'boolean') {
        this.$set(this.filters, key, false);
      } else {
        this.$set(this.filters, key, '');
      }
      this.applyFilters();
    },
          debounceFilters: debounce(async function() {
      await this.applyFilters();
    }, 750),
    applyFilters() {
      this.typeMenu = false;
      this.isViewedMenu = false;
      this.filters.page = this.startPage;
      this.filters.size = this.startSize; 
      this.toGetNotifications();
    },
            async toGetSegments() {
                try {
                    await this.$store.dispatch('segments/awaitGetSegments');
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 
                    this.$t('just_error'), 
                    detail: error
                    , 
                    life: 4000 })
                }
            },
            async toGetNotifications() {
                this.loadingFlag = true;
                try {
                    await this.$store.dispatch('notifications/awaitGetNotifications', this.filters);
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 
                    this.$t('just_error'), 
                    detail: error
                    , 
                    life: 4000 })
                } finally{
                  this.$store.commit('paggination/SET_LOADING_FLAG', false);
                    this.loadingFlag = false
                }
            },
            handleSubmit(validate) {
        validate().then(valid => {
          this.typeError = false
          if (valid) {
            if (!this.notification.type) {
              this.typeError = true
            } else{
              if (this.isEditNotification) {
                this.editNotification();
              }else{
                this.addNotification();
              }
            }
          }
        })
      },
      async addNotification(){
        this.formLoading = true;
        try {
          const notif = this.notification
          const notificationData = {
            all_users: this.isType === 'all',
            type: notif.type,
            message: notif.message
          }
          if (notif.segment_id) {
            notificationData.segment_id = notif.segment_id
          }
            await this.$store.dispatch('notifications/awaitCreateNotificationToPlayer', notificationData);
            this.$toast.add({
              severity: 'success',
              summary: this.$t('notification_added'),
              life: 4000
            })
            this.notificationModal = false;
            this.notification = {
              type: 'MESSAGE',
              message: '',
              segment_id: '',
              all_users: true,
            };
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('just_error'),
              life: 4000})
          } finally{
            this.formLoading = false;
          }
      },
            //========================================================================================================================================================
            getIsViewedClass(is_viewed) {
      switch (is_viewed) {
        case true:
          return '_green';
        case false:
          return '_gray';
      }
    },
    getIsViewed(is_viewed) {
      switch (is_viewed) {
        case true:
          return this.$t('yes');
        case false:
          return this.$t('no');
      }
    },
    getTypeClass(type) {
      switch (type) {
        case 'BONUS':
          return '_green';
        case 'LEVEL':
          return '_gray';
          case 'MESSAGE': 
            return '_main';
            case 'BAN': 
              return '_red';
              case 'COMPENSATION':
                return '_yellow';
      }
    },
    getType(type) {
      switch (type) {
        case 'BONUS':
          return this.$t('bonus');
        case 'LEVEL':
          return this.$t('level');
          case 'MESSAGE': 
            return this.$t('message');
            case 'BAN': 
              return this.$t('ban');
              case 'COMPENSATION':
                return this.$t('compensation');
      }
    },
            //========================================================================================================================================================
            
        }

    }
</script>

<style lang="scss" scoped>

</style>